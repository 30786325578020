import React from 'react';

// import flexible content modules
import Banner from './banner';
import VideoBanner from './videoBanner';
import TextBlock from './textBlock';

const DynaContent = (props) => {
  //PROPS
  const { rows } = props;
  //PROPS

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (!!rows) {
    return rows
      .filter((o) => o !== null)
      .map((e, index) => {
        const type = capitalizeFirstLetter(Object.keys(e)[0]);

        const Text = TextBlock;
        const components = {
          Banner,
          VideoBanner,
          Text,
        };
        const Component = components[type];
        return Component && <Component key={index} data={e} />;
      });
  } else {
    return null;
  }
};

export default DynaContent;
