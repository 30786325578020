import HTMLReactParser from 'html-react-parser';
import React from 'react';

import { Wrapper } from './TextBlock.styles';

const TextBlock = (props) => {
  //PROPS
  const {
    data: { text },
  } = props;
  //PROPS

  return <Wrapper>{HTMLReactParser(text)}</Wrapper>;
};

export default TextBlock;
