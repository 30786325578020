import React from 'react';

import { graphql } from 'gatsby';
import DynaContent from 'components/dcontent';

const TemplateFabrica = (props) => {
  //PROPS
  const {
    data: {
      page: { dcontent },
    },
  } = props;

  //PROPS

  return (
    <>
      <DynaContent rows={dcontent.dcontent} />
    </>
  );
};

export const query = graphql`
  query Fabrica($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      dcontent {
        dcontent {
          ... on WpPage_Dcontent_Dcontent_Banner {
            banner {
              imageFirst
              text
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          ... on WpPage_Dcontent_Dcontent_Video {
            videoBanner {
              mediaItemUrl
            }
          }
          ... on WpPage_Dcontent_Dcontent_Textblock {
            text
          }
        }
      }
    }
  }
`;

export default TemplateFabrica;
