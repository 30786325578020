import React from 'react';

import HTMLReactParser from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Wrapper, Row } from './Banner.styles';

const Banner = (props) => {
  //PROPS
  const {
    data: {
      banner: { imageFirst, text, image, title },
    },
  } = props;
  //PROPS

  return (
    <Wrapper>
      <Row>
        <div
          className={
            imageFirst ? 'col col--text order2' : 'col col--text order1'
          }
        >
          <h1>{title}</h1>
          {text && HTMLReactParser(text)}
        </div>

        <div className={imageFirst ? 'col order1' : 'col order2'}>
          <GatsbyImage
            image={image?.localFile?.childImageSharp?.gatsbyImageData}
            alt={image?.altText}
          />
        </div>
      </Row>
    </Wrapper>
  );
};

export default Banner;
